/*
 * @Author: 刘逛 180768871@qq.com
 * @Date: 2023-04-26 10:40:55
 * @LastEditors: 刘逛 180768871@qq.com
 * @LastEditTime: 2024-11-21 17:29:17
 * @FilePath: \admin_pc\src\http\api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const baseUrl = 'https://erpapi.winmatch.cn/admin/'
export default {
  // 首页
  Admin: {
    Login: baseUrl + "login",
    UpdatePassword: baseUrl + "update-password",
  },
  Goods: {
    List: baseUrl + "goods",
    Create: baseUrl + "goods",
    Info: baseUrl + "goods",
    Update: baseUrl + "goods",
    WarehouseGoods: baseUrl + "goods/warehouse/goods",
    AllGoods: baseUrl + "goods/all/goods",
    WarningGoods: baseUrl + "goods/warning/goods",
  },
  Category: {
    List: baseUrl + "category",
    Create: baseUrl + "category",
    Info: baseUrl + "category",
    Delete: baseUrl + "category",
    New: baseUrl + "category/new",
  },
  Order: {
    List: baseUrl + "order",
    Create: baseUrl + "order/create",
    Info: baseUrl + "order",
    Delete: baseUrl + "order",
    Update: baseUrl + "order",
    Out: baseUrl + "order/out",
    Apply: baseUrl + "order/apply",
    shipment: baseUrl + "order",
    Price: baseUrl + "order/payment",
    DownloadPdf: baseUrl + "order/downloadPdf",
    DownloadPaymentPdf: baseUrl + "order/downloadPaymentPdf",
  },
  Warehouse: {
    List: baseUrl + "warehouse",
    Create: baseUrl + "warehouse",
    Info: baseUrl + "warehouse",
    Delete: baseUrl + "warehouse",
    Update: baseUrl + "warehouse",
  },
  WarehouseIn: {
    List: baseUrl + "warehouseIn",
    Create: baseUrl + "warehouseIn",
    Info: baseUrl + "warehouseIn",
    Delete: baseUrl + "warehouseIn",
    Update: baseUrl + "warehouseIn",
    GoodsList: baseUrl + "warehouseIn/getGoodsList",
    Status: baseUrl + "warehouseIn",
  },
  WarehouseOut: {
    List: baseUrl + "warehouseOut",
    Create: baseUrl + "warehouseOut",
    Info: baseUrl + "warehouseOut",
    Delete: baseUrl + "warehouseOut",
    Update: baseUrl + "warehouseOut",
    GoodsList: baseUrl + "warehouseOut/getGoodsList",
  },
  Allot: {
    List: baseUrl + "allot",
    Create: baseUrl + "allot",
    Info: baseUrl + "allot",
    Delete: baseUrl + "allot",
    Update: baseUrl + "allot",
    GoodsList: baseUrl + "allot/getGoodsList",
    export: baseUrl + "export/warehouseAllot",
  },
  Level: {
    List: baseUrl + "level",
    Create: baseUrl + "level",
    Info: baseUrl + "level",
    Delete: baseUrl + "level",
    Update: baseUrl + "level",
  },
  Role: {
    List: baseUrl + "role",
    Create: baseUrl + "role",
    Info: baseUrl + "role",
    Delete: baseUrl + "role",
    Update: baseUrl + "role",
  },
  Permission: {
    List: baseUrl + "permission",
    Create: baseUrl + "permission",
    Info: baseUrl + "permission",
    Delete: baseUrl + "permission",
    Update: baseUrl + "permission",
  },
  Region: {
    List: baseUrl + "region",
    Create: baseUrl + "region",
    Info: baseUrl + "region",
    Delete: baseUrl + "region",
    Update: baseUrl + "region",
    Level: baseUrl + "region/two/level",
  },
  Reimburse: {
    List: baseUrl + "reimburse",
    Create: baseUrl + "reimburse",
    Info: baseUrl + "reimburse",
    Delete: baseUrl + "reimburse",
    Update: baseUrl + "reimburse",
    Apply: baseUrl + "reimburse/apply",
  },
  Package: {
    List: baseUrl + "package",
    Create: baseUrl + "package",
    Info: baseUrl + "package",
    Update: baseUrl + "package",
  },
  Banner: {
    List: baseUrl + "ad",
    Create: baseUrl + "ad",
    Info: baseUrl + "ad",
    Update: baseUrl + "ad",
    Delete: baseUrl + "ad",
  },
  Credential: {
    AllCredential: baseUrl + "all-credential",
    CreateCredential: baseUrl + "create-credential",
    DeleteCredential: baseUrl + "delete-credential",
    UpdateCredential: baseUrl + "update-credential",
  },
  Config: {
    Info: baseUrl + "config",
  },
  Member: {
    List: baseUrl + "member",
    Create: baseUrl + "member",
    Info: baseUrl + "member",
    Update: baseUrl + "member",
    Delete: baseUrl + "member",
    Address: baseUrl + "member/get/address",
    template: baseUrl + "member/import/template",
    import: baseUrl + "member/import/member",
    discountPrice: baseUrl + "member/discountPrice",
  },
  Account: {
    List: baseUrl + "account",
    Create: baseUrl + "account",
    Info: baseUrl + "account",
    Update: baseUrl + "account",
    Delete: baseUrl + "account",
  },
  Statistics: {
    Foundation: baseUrl + "statistics",
    Trend: baseUrl + "statistics/trend",
    GetProductStock: baseUrl + "statistics/getProductStock",
    GetProductStockDetail: baseUrl + "statistics/getProductStockDetail/new",
  },
  System: {
    getAuditOrder: baseUrl + "system/auditOrder",
    auditWarehouse: baseUrl + "system/auditWarehouse",
    getAuditWarehouse: baseUrl + "system/getAuditWarehouse",
    auditReimburse: baseUrl + "system/auditReimburse",
    getAuditReimburse: baseUrl + "system/getAuditReimburse",
    Config: baseUrl + "system",
  },
  Activity: {
    List: baseUrl + "activity",
    Create: baseUrl + "activity",
    Info: baseUrl + "activity",
    Update: baseUrl + "activity",
    Delete: baseUrl + "activity",
  },
  Export: {
    List: baseUrl + "export",
    warehouseIn: baseUrl + "export/warehouseIn",
    warehouseOut: baseUrl + "export/warehouseOut",
    order: baseUrl + "export/order",
    orderDetail: baseUrl + "export/orderDetail",
    productStock: baseUrl + "export/productStock",
    productStockDetail: baseUrl + "export/productStockDetail",
    summeryProductStockDetail: baseUrl + "export/summeryProductStockDetail",
  },
  Finance: {
    statistics: baseUrl + "finance/statistics",
    getPayOrder: baseUrl + "finance/getPayOrder",
  },
};
