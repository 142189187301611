export default {
  menu: {
    menu_console: "Console",
    menu_merchandise_management: "Merchandise Management",
    menu_item_product_list: "Product List",
    menu_item_product_type: "CATEGORY",
    menu_item_activity_list: "Activity List",
    menu_warehouse_management: "Warehouse Management",
    menu_item_warehouse_list: "Warehouse List",
    menu_item_inbound_management: "Inbound Management",
    menu_item_outbound_management: "Outbound Management",
    menu_item_transfer_order_management: "Transfer Order Management",
    menu_item_product_alerts: "Product Alerts",
    menu_financial_management: "Financial Management",
    menu_item_reimbursement_management: "Reimbursement Management",
    menu_item_summary: "Summary",
    menu_item_invoicing: "Invoicing",
    menu_system_configuration: "System Configuration",
    menu_item_basic_configuration: "Basic Configuration",
    menu_item_carousel_list: "Carousel List",
    menu_item_manager_list: "Manager List",
    menu_item_credit_rating: "Credit Rating",
    menu_item_role_list: "Role List",
    menu_item_address_management: "Address Management",
    menu_item_order_approval_flow: "Order Approval Flow",
    menu_item_warehouse_approval_flow: "Warehouse Approval Flow",
    menu_item_financial_approval_flow: "Financial Approval Flow",
    menu_item_export_record: "Export Record",
    menu_order_management: "Order Management",
    menu_order_list: "Order List",
    menu_member_management: "Member Management",
    menu_member_list: "Member List",
  },
  common: {
    more: "View More",
    title: "Pesticide ERP Management System", // Webpage title
    description: "Pesticide ERP Management System Description", // Webpage description
  },
  // Login form
  loginForm: {
    title: "Login",
    username_label: "Username",
    password_label: "Password",
    btn_name: "Submit",
  },
  // Common components
  commonComponents: {
    create_btn: "Create",
    edit_btn: "Edit",
    del_btn: "Delete",
    info_btn: "Details",
    search_btn: "Search",
    rest_btn: "Reset",
    delete_all: "Delete All",
    operation: "Operation",
    warning: "Warning",
    void_btn: "Review",
    cancel: "Cancel",
    please_enter_content: "Please enter content...",
    do_not_display: "Do not display",
    confirm_delete_prompt: "Are you sure you want to delete?",
    display: "Display",
    confirm: "Confirm",
    open: "Open",
    close: "Close",
    import: "Import",
    bulk_import: "BulkImport",
    by: "Passed",
    rejected: "Reject",
    remark: "Remark",
    to_be_reviewed: "To be reviewed",
    void: "Void",
    submission: "Submission",
    in_review: "In Review",
    download: "Download",
    download_the_template: "DownloadTheTemplate",
    in_progress: "In Progress",
    completed: "Completed",
    Inventory_btn: "Inventory", // 点击查看库存信息
    back_btn: "Back",
    discount_btn: "Discount",
    disable_btn: "Disable",
    enable_btn: "Enable",
    grade_btn: "Grade",
  },
  // Common messages
  commonMsg: {
    select_data_manipulated: "Please select the data to be manipulated",
    please_enter_categoryname: "Please enter category name",
    through_the_audit: "Are you sure you want to approve the review?",
    rejection_review: "Are you sure you want to reject the review?",
    void_review: "Are you sure you want to void the review?",
  },
  // Goods
  goods: {
    id: "Goods ID",
    product_id: "Product ID",
    commodity_information: "Commodity Information",
    category_id: "Category ID",
    commodity_picture: "Commodity Picture",
    goods_sn: "Goods SN",
    name: "Goods Name",
    secondary_name: "Secondary Goods Name",
    description: "Description",
    sort: "Sort",
    status: "Status",
    off_shelf: "Off Shelf",
    on_shelf: "On Shelf",
    specification_settings: "Specification Settings",
    specification: "Specification",
    single_spec: "Single Specification",
    multi_spec: "Multiple Specifications",
    price: "Price",
    cost_price: "Cost Price",
    freight: "Freight",
    specification_name: "Specification Name",
    specification_val: "Specification Value",
    use_the_sign_to_split_multiple_values_for_example_white_black:
      "Use # to split multiple values, e.g., white#black",
    add_new_specification: "Add New Specification",
    generate_specification_list_immediately:
      "Generate Specification List Immediately",
    add_specification_value: "Add Specification Value",
    parameter_name: "Parameter Name",
    parameter_value: "Parameter Value",
    specification_name_already_exists:
      "Specification name already exists, please add specification directly",
    goods_sku_id: "SKU ID",
    custom_parameters: "Custom Parameters",
    add_parameter: "Add Parameter",
    product_details: "Product Details",
    previous: "Previous",
    next: "Next",
    submit: "Submit",
    search_goods_name: "Goods Name",
    search_goods_name_placeholder: "Please enter goods name",
    search_goods_category: "Goods Category",
    search_goods_category_placeholder: "Please select a category",
    number:"quantity",
    inventory:"inventory",
  },
  // Table
  table: {
    id: "ID",
    address: "Address",
    secondary_address: "SecondaryAddress",
    main_warehouse: "Is Main Warehouse",
    type: "Type",
    sort: "Sort",
    category_name: "Category Name",
    goods_sn: "Goods SN",
    name: "Name",
    product_name: "Product Name",
    price: "Price",
    cost_price: "Cost Price",
    show_image: "Symbol",
    stock: "Stock",
    desc: "Description",
    sell_nums: "Sales",
    status: "Status",
    is_hidden: "Is Hidden",
    warning_stock: "Stock Warning",
    warehouse_name: "Warehouse Name",
    from_warehouse_name: "Source Warehouse Name",
    to_warehouse_name: "Destination Warehouse Name",
    receipt_date: "Receipt Date",
    receipt_type: "Receipt Type",
    receipt_name: "Receipt Name",
    outbound_date: "Outbound Date",
    outbound_type: "Outbound Type",
    outbound_name: "Outbound Name",
    remark: "Remark",
    level: "Level",
    quota: "Quota",
    operation: "Operation",
    en_name: "English Name",
    create_time: "Creation Time",
    update_time: "Update Time",
    delete_time: "Delete Time",
    title: "Title",
    begin_time: "Start Time",
    end_time: "End Time",
    date: "Time",
    type_desc: "Type Description",
    unpaid: "Unpaid",
    paid: "Paid",
    NotInvoiced: "NotInvoiced",
    Invoiced: "Invoiced",
    reason:"Reason",
    operation_time:"Operation Time",
     order_status: "Order Status"
  },
  // Console
  console: {
    today_under_the_singular: "Today's Order Count",
    yesterday_under_the_singular: "Yesterday's Order Count",
    today_s_sales_amount: "Today's Sales Amount",
    yesterday_s_sales_amount: "Yesterday's Sales Amount",
    amount_received_today: "Today's Received Amount",
    amount_received_yesterday: "Yesterday's Received Amount",
    amount_not_received_today: "Today's Unreceived Amount",
    amount_not_received_yesterday: "Yesterday's Unreceived Amount",
    daily_order_volume_statistics: "Daily Order Volume Statistics",
  },
  // Category
  category: {
    category_name: "Category Name",
    sort: "Sort",
    status: "Status",
    select_the_icon: "Select Icon",
  },
  // Activity
  avtivity: {
    title: "Activity Title",
    type: "Type",
    hot_sale: "Hot Sale",
    promotion: "Promotion",
    activity_cover: "Activity Cover",
    description: "Description",
    start_time: "Start Time",
    end_time: "End Time",
    active_goods_list: "Active Goods List",
    select_a_list_of_items_to_import: "Select a List of Items to Import",
    active_inventory: "Active Inventory",
    price: "Price",
    open_activity: "Are you sure you want to open the activity?",
    close_activity: "Are you sure you want to close the activity?",
  },
  // Warehouse
  warehouse: {
    name: "Warehouse Name",
    address: "Warehouse Address",
    warning_stock: "Stock Warning",
    desc: "Description",
    type: "Type",
    inbound_name: "Inbound Name",
    single_number: "Single Number",
    main_warehouse: "Main Warehouse",
    sub_warehouse: "Sub Warehouse",
    receipt_time: "Receipt Time",
    warehousing: "Warehouse Entry",
    warehousing_goods: "Warehousing Goods",
    receipt_quantity: "Receipt Quantity",
    select_a_list_of_items_to_import: "Select a List of Items to Import",
    transfer_receipt: "Transfer Receipt",
    sales_return_receipt: "Sales Return Receipt",
    purchase_into_warehouse: "Purchase into Warehouse",
    issue_name: "Issue Name",
    remarks: "Remarks",
    issue_date: "Issue Date",
    outbound_warehouse: "Outbound Warehouse",
    outbound_goods: "Outbound Goods",
    quantity_of_issue: "Issue Quantity",
    details_of_issue_document: "Issue Document Details",
    order_review: "Order Review",
    approval_status: "Approval Status",
    reason_for_rejection_or_void: "Reason for Rejection or Void",
    sales_issue: "Sales Issue",
    transfer_doc_name: "Transfer Doc Name",
    transfer_date: "Transfer Date",
    transfer_warehouse: "Transfer Warehouse",
    allocate_goods: "Allocate Goods",
    allocate_review:"Allocate Review",
  },
  reimburse: {
    price: "Amount",
    date: "Time",
    type: "Use",
    desc: "Description",
    reasons_for_reimbursement: "Because Of What What Reimbursement",
    upload_invoice: "Approve And Upload Invoices",
    file_uploaded: "File Uploaded",
    daily_expenses: "Daily Expenses",
  },
  statistics: {
    order_pay_price: "Order Payment Amount",
    pay_price: "Amount Paid",
    no_pay_price: "Unpaid Amount",
    order_sn: "Order Number",
    sale_name: "Sales Name",
    nickname: "Customer Nickname",
    phone: "Mobile Phone Number",
    repayment_time: "Repayment Time",
    total_order_amount: "Total Order Amount",
    collect_price: "Received Amount",
    sub_price: "Remaining Amount",
    extension_time: "Extension Time",
    pay_type_desc: "Payment Type",
  },
  purchasing: {
    receipt_issue_details: "Receipt Issue Details",
    out_stock: "Out Stock",
    in_stock: "In Stock",
    quantity: "Inventory",
  },
  sys: {
    basic_configuration: "Basic Configuration",
    customer_service_telephone: "Customer Service Telephone",
    phone_registration_switch: "Phone Registration Switch",
  },
  banner: {
    title: "Title",
    jump_link: "Jump Address",
    status: "Status",
    off_the_shelf: "Off The Shelf",
    shelf: "Shelf",
    picture: "Picture",
  },
  manager: {
    header: "Avatar",
    administrator_name: "Administrator Name",
    account: "Account",
    phone: "Phone",
    password: "Password",
    status: "Status",
    warehouse_ids: "Associated Warehouse",
    role_id: "Role Name",
    leader_id: "Superior Leadership",
  },
  rating: {
    name: "Name",
    quota: "Quota",
    status: "Status",
    level: "Grade",
  },
  role: {
    name: "Name",
    permission_management: "Permission",
    status: "Status",
    desc: "Description",
  },
  address: {
    level_1_area: "Level 1 Area",
    secondary_area: "Secondary Area",
    level_3_area: "Level 3 Area",
    name: "Address Name",
    pid: "Superior City",
    level: "Grade",
  },
  order_approval: {
    amount_greater: "Amount Greater",
    end: "End",
    configuration: "Configuration",
    text1: "Approval Personnel Of The Link",
    all_personnel: "All Personnel",
    people_selected: "People Selected",
    sales: "Sales",
    sales_manager: "Sales Manager",
    wind_control_audit: "Wind Control Audit",
    general_manager: "General Manager",
    finance: "Finance",
    export_summary: "Export Summary",
    prohibit_configuration: "Prohibit Configuration For The Current Node",
  },
  order: {
    order_goods_export: "Order Goods Export",
    export_excel: "Export Excel",
    details: "Details",
    receipt: "Collection",
    shipment: "Shipments",
    audit: "Audit",
    All: 'ALL',
    toBeReviewed: 'ToBeReviewed',
    confirmed: 'CONFIM',
    payment: 'PAYMENT',
    treatLibrary: 'DELIVERY',
		accomplish: 'COMPLETED',
		HandleCancel: 'CANCEL',
		invoicing: 'INVOICE',
    
    payment_type:"payment type",
    first_payment_after_goods:"payment first",
    goods_before_payment:"goods first, payment later",
    repayment_time:"repayment time",
    buy_goods:"select goods",
    select_a_list_of_items_to_import:"select a list of items to import",
    customer_information:"customer information",
    customer_name:"customer name",
    contact_phone:"contact phone",
    contact_email:"email",
    sales_name:"sales name",
    picking_warehouse:"pickup warehouse",
    picking_code:"pickup code",
    payment_amount:"payment amount",
    stay:"waiting",
    passed:"passed",
    rejected:"rejected",
    reviewer:"reviewer",
    reason_for_rejection:"rejection reason",
    order_review:"order review",
    approval_status:"approval status",
    receipt_amount:"receipt amount",
    received_amount: "Amount collected",
    uncollected_amount: "Amount not collected",
    cash: "Cash",
    bank_card: "Bank card",
    start_time: "Start time",
    deadline: "Deadline",
    confirm_to_ship: "Are you sure you want to ship?",
    whether_to_pay: "Payment",
    status:"Status",
   shipment_note:"Shipment Note"
  },

  member:{
    gender:"Gender",
    quota:"Credit Limit",
    level_name:"Customer Level",
    discount_price:"Disaccount price",
    province_name:"region ",
    sale_name:"Sales people",
    sub_price:"Balance Payment",
    total_price:" Total Amount",
    total:"Total Orders",
    phone:"Phone No",
    email:"Email",
    name:"Users name",
    avatar:"Avatar",
    pwd:"Password",
    no_grade:"No Grade",
    male:"Man",
    female:"Girl",
    normal:"Normal",
    disable:"Disable",
    modify_member_discount_amount:"Modify Member Discount Amount",
    open_member: "Are You Sure You Want To Enable Members?",
    close_member: "Are You Sure You Want To Disable Members?",
  }
};
